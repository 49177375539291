.home-bg {
    background-image: linear-gradient(to top, rgba(70, 21, 17, 1), rgba(70, 21, 17, 0));
}

svg:focus {
    outline: none;
}

input#file-upload-button {
    cursor: pointer !important;
}

input[type="file" i]::-webkit-file-upload-button {
    cursor: pointer !important;
}

.genealogy-tree-level-bg .genealogy-tree-level-bg-item:nth-child(even) {
    background-color: #FFFFFF;
    border-left: 4px solid #F2B84C; 
}
  
.genealogy-tree-level-bg .genealogy-tree-level-bg-item:nth-child(odd) {
    background-color: #F3F3F3;
    border-left: 4px solid #FFEAAE; 
}
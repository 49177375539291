@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/styles/loading.scss';
@import './assets/styles/base.scss';
@import './assets/styles/form.scss';
@import './assets/styles/table.scss';
@import './assets/styles/toast.scss';
@import './assets/styles/datePicker.scss';
@import './assets/styles/pagination.scss';

@layer base {
  body {
    margin: 0;
    padding: 0 0;
    background-color: #f4f4f4;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }

  * {
    min-width: 0;
    min-height: 0;
  }

  input::placeholder {
    font-weight: 500;
    color: #000;
    opacity: 0.3;
  }

  .required-after:after {
    content: ' *';
    color: red;
  }

  .required-before::before {
    content: ' *';
    color: red;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hidden-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .scrollbar-custom::-webkit-scrollbar {
    width: 4px;
    height: 50px;
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    background: #475467;
    border-radius: 8px;
  }

  .mini-scrollbar::-webkit-scrollbar {
    width: 4px;
  }

  .mini-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-primary
  }
  
  input[type='button'] {
    cursor: pointer;
  }
}

.container-base {
  // max-width: 1202px;
  // width: 65vw;
  width: 1202px;
}

.react-transform-wrapper {
  min-width: 800px;
  min-height: 600px;
}

.genealogy-tree .genealogy-tree-item::before {
  content: '';
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  // background-color: #f4f4f4;
  z-index: -1;
}

.genealogy-seven-center.css-1vs3jbz::before {
  content: '';
  width: 0px !important;
}

.genealogy-tree-container + ul::before {
  content: '';
  height: 40px;
}

.genealogy-tree-container + ul {
  padding-top: 40px;
}

.genealogy-tree-container-14 + ul::before {
  content: '';
  height: 125px;
}

.genealogy-tree-container-14 + ul {
  padding-top: 125px;
}

.genealogy-tree-container-14-alone + ul::before {
  content: '';
  height: 140px;
}

.genealogy-tree-container-14-alone + ul {
  padding-top: 140px;
}

.genealogy-seven-center.css-1vs3jbz::after {
  content: '';
  width: 400px !important;
}

// .log_tooltip .react-tooltip-arrow {
//   @apply bg-gray-300;
// }

.log_tooltip.react-tooltip {
  opacity: 1 !important;
}

input[type='file'] {
  color: rgba(0, 0, 0, 0)
}

#inputFile::-webkit-file-upload-button {
  visibility: hidden;
}

.ql-align-center {
  text-align: center;
}

.home-history ul {
  list-style: disc inside;
}

.level-bg-tooltip {
  position: absolute;
  bottom: 0; /* Đặt tooltip ở dưới cùng */
  transform: translateY(100%); /* Dịch chuyển tooltip xuống dưới div */
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  pointer-events: none; /* Đảm bảo tooltip không cản trở sự kiện chuột */
}

.triangle {
  position: absolute;
  top: 50%;
  left: -8px; /* Adjust this value to place the triangle */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid; /* Same color as tooltip background */
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  @apply border-r-slate-500
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
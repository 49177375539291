.Toastify__toast-container {
  padding: 0px !important;
  top: 2rem !important;
  width: 464px !important;
}

.Toastify__toast {
  min-height: 0px !important;
  padding: 13px 17px 13px 17px !important;
  border-radius: 4px !important;
}

.Toastify__toast > .Toastify__toast-body {
  padding: 0px !important;
}

.Toastify__toast > .Toastify__toast-body > div {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.Toastify__toast > .Toastify__toast-body > .Toastify__toast-icon > svg {
  width: 22px !important;
  height: 22px !important;
}

.Toastify__toast > .Toastify__close-button {
  opacity: 1 !important;
  align-self: center !important;
  color: #F2B84F !important;
  font-weight: 300;
}

.Toastify__toast--success {
  // background-color: #007b401a !important;
  background-color: white !important;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
  color: #007B40 !important;
  border: 1px solid #e0e0e0;
}

.Toastify__toast--error {
  // background-color: rgba(240, 61, 62, 0.1) !important;
  background-color: white !important;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
  color: #F03D3E !important;
  border: 1px solid #e0e0e0;
}

.Toastify__toast--warning {
  // background-color: rgba(216, 73, 16, 0.1)  !important;
  background-color: white !important;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
  color: #D84910 !important;
  border: 1px solid #e0e0e0;
}

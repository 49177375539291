.pagination {
    @apply flex justify-center w-full h-full space-x-1;
    .page-item {
        @apply  text-sm relative  border border-gray-400 rounded-lg bg-white hover:bg-secondary;
        .visually-hidden {
            @apply hidden;
        }
        &.active {
            @apply bg-secondary;
        }
        .page-link {
            min-width: 34px;
            height: 34px;
            @apply flex text-sm items-center justify-center px-1;
        }
    }
}
.root {
  position: relative;
  transform: translateZ(0);
  overflow: auto;
  -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.root::-webkit-scrollbar {
  display: none;
}

.root::-webkit-scrollbar-thumb {
  @apply bg-primary
}

.point {
  position: absolute;
  width: 0;
  height: 0;
  transform: translate(0, 0) scale(1);
  transform-origin: center;
  will-change: transform;
}

.canvas {
  position: absolute;
  transform: translate(-50%, -420px);
  min-width: 600px !important;
}
  
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 2.2rem !important;
}

.react-datepicker-popper {
    z-index: 20 !important;
}

.date-ranger {
    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            outline: none;
            input {
                outline: none;
                color: #595959;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}
.table-container {
    // border: 1px solid rgba(70, 21, 17, 0.4);
    margin-top: 6px;
    padding: 5px 0px 10px 0px;
    @apply w-full h-auto relative overflow-auto;
}

.table {
    
    thead tr {
        @apply text-sm text-left  text-table_thead_text;
    
        span {
          @apply whitespace-nowrap font-normal leading-5;
        }
    }

    thead tr th {
        padding: 6.5px 9px 10px 9px;
    }

    tbody {
        border-collapse: collapse !important;
    }

    tbody tr {
        border: 1px solid rgba(70, 21, 17, 0.4);
        border-collapse: collapse !important;
        color: #595959;
        font-size: 14px;
        font-weight: 400;
    }

    tbody tr td {
        padding: 16px 12px;
    }

    &-title {
        @apply flex items-center gap-2;
    }

    &-primary-btn {
        @apply inline-flex items-center justify-center px-3 py-1 border border-transparent 
        text-sm leading-5 font-medium rounded-10px text-bg_brown bg-table_primary_btn 
        hover:bg-yellow_F9D876 disabled:bg-table_primary_btn/50 focus:outline-none transition duration-150 ease-in-out cursor-pointer;
    }

    &-second-btn {
        @apply inline-flex items-center justify-center px-3 py-1 border border-table_primary_btn 
        text-sm leading-5 font-medium rounded-10px text-bg_brown bg-white hover:bg-yellow_F9D876 
        focus:outline-none transition duration-150 ease-in-out disabled:bg-table_primary_btn/50 cursor-pointer;
    }
}
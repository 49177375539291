.form {

    &-group {
        @apply flex items-center;
    }

    &-label {
        @apply text-sm font-normal leading-5 text-gray_b5b5b5;
    }

    &-input {
        @apply block w-full h-8 rounded-md border border-input_border py-2 text-sm font-normal text-color_text 
        placeholder:text-place_holder placeholder:font-normal placeholder:text-sm placeholder:opacity-100 focus:outline-none focus:border-focus
         sm:text-sm sm:leading-6 px-3 disabled:bg-input_disabled;
    }

    &-input-area {
        @apply block w-full rounded-md border border-input_border py-2 text-sm font-normal text-color_text 
          placeholder:text-place_holder placeholder:font-normal placeholder:text-sm placeholder:opacity-100 focus:outline-none focus:border-focus
         sm:text-sm sm:leading-6 px-3 disabled:bg-input_disabled;
    }

    &-error {
      @apply border border-input_border_error;
    }

    &-normal-text {
        @apply text-sm font-normal leading-5 text-color_text;
    }

    &-select {
        // max-height: 32px;
        // border-radius: 4px;
        // border: 1px solid #E5E5E5;
        @apply text-sm xl:text-base w-full;
    
        .select__single-value {
          @apply text-sm xl:text-base text-color_text font-medium;
        }
    
        .select__control {
          // max-height: 32px;
          border-radius: 4px;
          padding-left: -1px;
          @apply border border-gray-500;
        }
    
        .select__value-container {
          padding-left: 6px;
          padding-right: 6px;
        }
    
        .select__indicator-separator {
          border: none;
          display: none;
        }
    
        .select__input {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .select__placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #E1E1E1;
        }
    
        .select__input:disabled {
          color: #7D7D7D !important;
        }
    
        .select__single-value {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
    
        .select__single-value.select__single-value--is-disabled {
          color: #7D7D7D !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 20px !important;
        }
    
        .select__menu {
          @apply p-2 xl:p-4;
        }
    
        .select__option,
        .select__option--is-selected {
          
        }
    }

    &-select-32 {
      max-height: 32px;
      border-radius: 4px;
      // border: 1px solid #E5E5E5;
      @apply text-sm xl:text-base w-full ;
  
      .select__single-value {
        @apply text-sm xl:text-base text-color_text font-normal;
      }
  
      .select__control {
        max-height: 32px;
        min-height: 32px;
        border-radius: 4px;
        padding-left: -1px;
        border-color: #E5E5E5;
        // @apply border border-gray-e0;

        &:hover {
          @apply border border-focus;
        }

        &--is-focused {
          @apply shadow-none;
        }
  
        .select__indicators {
          width: auto;
          max-height: 32px;
        }
  
        .select__dropdown-indicator {
          width: 32px;
          max-height: 32px;
          color: #F2B84F;
          margin-top: -4px;
        }
      }
  
      .select__value-container {
        padding-left: 8px;
        padding-right: 2px;
        // margin-top: -2px;
        @apply text-sm
      }
  
      .select__indicator-separator {
        border: none;
        display: none;
      }
  
      .select__input-container {
        margin: 0px;
        padding: 0px;
  
        .select__input {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
  
      .select__placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color:#E1E1E1;
      }
  
      .select__input:disabled {
        color: #7D7D7D !important;
      }
  
      .select__single-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
  
      .select__single-value.select__single-value--is-disabled {
        color: #7D7D7D !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
      }
  
      .select__menu {
        @apply p-1 xl:p-2;
      }
  
      .select__option,
      .select__option--is-selected {
        padding: 2px;
        @apply text-sm
      }
    }

    &-select-32-error {
      max-height: 32px;
      border-radius: 4px;
      // border: 1px solid #E5E5E5;
      @apply text-sm xl:text-base w-full ;
  
      .select__single-value {
        @apply text-sm xl:text-base text-black font-normal;
      }
  
      .select__control {
        max-height: 32px;
        min-height: 32px;
        border-radius: 4px;
        padding-left: -1px;
        border-color: #F03D3E;
        // @apply border border-gray-e0;

        &:hover {
          @apply border border-input_border_error;
        }

        &--is-focused {
          @apply shadow-none;
        }
  
        .select__indicators {
          width: auto;
          max-height: 32px;
        }
  
        .select__dropdown-indicator {
          width: 32px;
          max-height: 32px;
          color: #F2B84F;
          margin-top: -4px;
        }
      }
  
      .select__value-container {
        padding-left: 8px;
        padding-right: 2px;
        // margin-top: -2px;
        @apply text-sm
      }
  
      .select__indicator-separator {
        border: none;
        display: none;
      }
  
      .select__input-container {
        margin: 0px;
        padding: 0px;
  
        .select__input {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
  
      .select__placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color:#E1E1E1;
      }
  
      .select__input:disabled {
        color: #7D7D7D !important;
      }
  
      .select__single-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
  
      .select__single-value.select__single-value--is-disabled {
        color: #7D7D7D !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
      }
  
      .select__menu {
        @apply p-1 xl:p-2;
      }
  
      .select__option,
      .select__option--is-selected {
        padding: 2px;
        @apply text-sm
      }
    }
}

.custom-radio {
    @apply flex items-center justify-center text-sm font-normal text-gray_595959;
}

.custom-radio input[type='radio'] {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #F9D876;
    border-radius: 50%;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.custom-radio input[type='radio']:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    // margin: 25% auto;
    border-radius: 50%;
}

.custom-radio input[type='radio']:checked:before {
    background: #F9D876;
}

  